<template>
  <div>
    <div>
      <b-card title="Edit Term Condition Project Warranty">
        
        <b-row>

          <b-col cols="12">
            <label>
              Name
              <!-- <span style="color:red">(*)</span> -->
            </label>
  
            <b-form-input v-model="form.name" placeholder="Name">
            </b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
          <label>
            Module
            <!-- <span style="color:red">(*)</span> -->
          </label>

          <b-form-input v-model="form.module" placeholder="module (ex:paperless, project)">
          </b-form-input>
        </b-col>

        <b-col cols="6">
          <label>
            Sub Module
            <!-- <span style="color:red">(*)</span> -->
          </label>

          <b-form-input v-model="form.subModule" placeholder="sub module (ex:air conditioner, project)">
          </b-form-input>
        </b-col>

        </b-row>
        
      </b-card>
    </div>
    <div>
      <b-card>
        <div>
          <b-row>
            <b-col cols="1"><b>No</b></b-col>
            <b-col cols="5"><b>Main Point</b></b-col>
            <b-col cols="5"><b>Sub Point</b></b-col>
            <b-col cols="1"><b>Action</b></b-col>
          </b-row>
        </div>
        <!-- <div>{{ form.primary }}</div> -->
        <br />
        <div v-for="(it, idx) in form.primary" :key="idx">
          <b-row>
            <b-col cols="1">{{idx+1}}</b-col>
            <b-col cols="5"><b-form-textarea rows="4" v-model="form.primary[idx]"></b-form-textarea></b-col>
            <!-- <b-col cols="5">{{ it }}</b-col> -->
            <b-col cols="5"
              ><div v-for="(el, id) in form.secondary[idx]" :key="id">
                <!-- {{ el }} -->
                <b-form-textarea rows="4" v-model="form.secondary[idx][id]"></b-form-textarea>
              </div></b-col
            >
            <b-col cols="1"
              ><b-button variant="danger" style="mb-1" @click="delItem(idx)"
                >X</b-button
              ></b-col
            >
          </b-row>
          <br />
        </div>
        <b-col
          ><b-button variant="success" @click="doUpdateTermCondition">Save Changes</b-button></b-col
        >
      </b-card>
    </div>
    <div>
      <b-card title="Edit Term Condition Project Warranty">
        

        <b-col cols="12">
          <label>
            Main Point
            <!-- <span style="color:red">(*)</span> -->
          </label>

          <b-form-textarea v-model="main" placeholder="Main point">
          </b-form-textarea>
        </b-col>

        <b-col cols="12">
          <label>Sub Point</label>
          <b-form-textarea
            rows="5"
            placeholder="Sub of Main Point"
            v-model="sub"
          ></b-form-textarea>
        </b-col>
        <b-col cols="12">
          <label>Point Number</label>
          <v-select  
              class="style-chooser"
              v-model="selected" 
              :options="optionIndex"
              label="text">
              <template slot="selected-option" slot-scope="option">{{option.text}}</template>
            </v-select>
        </b-col>

        <b-col cols="12" class="mt-2">
          <b-button variant="success" @click="addToList(selected.id)">Add</b-button>
        </b-col>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { sleep }from '@/utils/utils.js'
import axios from "@/axios";

export default {
  props: ["id"],
  data() {
    return {
      form: {
        primary: [],
        secondary: [],
        module: "",
        subModule:"",
        name:""
      },
      list: [],
      main: "",
      sub: "",
      selected:""
    };
  },
  mounted() {
    setTimeout(() => {
      axios
        .get("/sales-and-purchase/drcc/term/" + this.id)
        .then((res) => {
          console.log("res__data", res.data);
          this.form = res.data.data;
        })
        .catch((err) => console.log({ err }));
    }, 500);
  },
  methods: {
    ...mapActions({
      updateTermCondition: "TermCondition/updateTermCondition",
    }),
    addToList(index) {
      let temp = [];
      if (this.sub !== "") temp = this.sub.split("\n");
      // console.log({temp})
      if (this.form.primary.length == 0 && this.form.secondary.length == 0) {
        this.form.secondary.push(temp);
        this.form.primary.push(this.main);
      }else{
        this.form.primary.splice(index, 0, this.main);
      this.form.secondary.splice(index, 0, temp);
      
      }
      

      // console.log('primary', this.form.primary)
      // console.log('secondary', this.form.secondary)

      this.main = "";
      this.sub = "";
      this.selected = null
    },
    delItem(index) {
      this.form.primary.splice(index, 1);
      this.form.secondary.splice(index, 1);
    },
    doUpdateTermCondition() {
      
      return this.updateTermCondition(this.form)
        .then(async () => {
          this.$bvToast.toast("Successfully update Terms and Condition", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          await sleep(1000)
          this.$router.go(-1);
        })
        .catch((err) => {
          let errorMessage = err.response.data.message;

          if (errorMessage !== undefined) {
            this.$bvToast.toast(errorMessage, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast("Error Processing Data", {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          }
        });
    },
  },
  computed: {
    // ...mapState({
    //   categories: (state) => {
    //     return state.category.categories.map((c) => ({
    //       value: c.id,
    //       text: c.name,
    //     }));
    //   },
    // }),
    optionIndex () {
      let init = []
      let primary = this.form.primary
      for (let i = 0; i < primary.length; i++) {
        const el = primary[i];
        init.push({
          id : i,
          text: `point ${i+1}`
        })
      }
      init.push({
          id : primary.length+1,
          text: `point ${primary.length+1}`
        })
      return init
    },
  },
};
</script>
